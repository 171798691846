body {
  margin: 0;
  font-family: -apple-system, 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pac-container {
  top: auto !important;
  bottom: 50px;
}